import { useEffect } from 'react';
import { StyleSheet, View, Text, Input, Modal } from 'react-native';
import Colors from './../scripts/Colors';
import Constants from './../scripts/Constants';

export const ExtrasModal = ({ secret, show, setShow}) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // let rid = window.location.pathname;
  // if (rid) {
  //   try {
  //     rid = parseInt(rid.replace('/', '').replace('/', ''));
  //   } catch (e) {
  //     rid = null;
  //   }
  // }
  // const recordId = rid;

  useEffect(() => {
    if (secret) {
      setShow(true);
    }
  });

  return (
    <View style={{ position: 'relative' }}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={show}
        onRequestClose={handleClose}
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <form action={Constants.base_url + "/email/submit"} method="post" encType="multipart/form-data">
              <Text style={{ color: '#FFF', fontSize: 26, display: 'block', fontFamily: 'OpenSans_800ExtraBold', marginBottom: 10 }}>Coming Soon</Text>
              <Text style={{ color: '#FFF' }}>Soon you will be able to do more with your edits -- buy a mug with your version, a shirt, or even an NFT! Leave your email address below to be notified when these features go live.</Text>
              { secret !== null ? <input style={{ display: 'none' }} type="hidden" value={secret} name="client_secret" /> : null }
              <label style={styles2.label}>Email Address</label>
              <input style={styles2.input} name="email" type="text" placeholder="jane@doe.com" />
              <label style={styles2.label}>Confirm Email</label>
              <input style={styles2.input} name="emailConfirm" type="text" placeholder="jane@doe.com" />
              <View style={styles.footer}>
                <a style={styles2.buttonGrey} href="/">
                  <Text style={styles2.buttonText}>
                    Go Back
                  </Text>
                </a>
                <button id="submit" type="submit" style={styles2.button}>
                  <Text style={styles2.buttonText}>
                    Submit
                  </Text>
                </button>
              </View>
            </form>
          </View>
        </View>
      </Modal>
      <View style={[styles.screen, show ? {} : {display: 'none'}]}></View>
    </View>
  );
}



const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(0, 0, 0, 0.7)'
  },
  modalView: {
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: 500,
    maxWidth: '90%',
    backgroundColor: Colors.dark
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center"
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center"
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: 30
  }
});

const styles2 = {
  buttonText: {
    fontSize: 12,
    color: '#FFF',
    fontFamily: 'OpenSans_800ExtraBold'
  },
  button: {
    fontSize: 14,
    backgroundColor: Colors.primary,
    borderRadius: 10,
    color: '#FFF',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 25,
    fontFamily: 'OpenSans_800ExtraBold',
    border: 'none'
  },
  buttonGrey: {
    textDecoration: 'none',
    fontSize: 14,
    backgroundColor: Colors.grey,
    borderRadius: 10,
    color: '#FFF',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 25,
    fontFamily: 'OpenSans_800ExtraBold',
    border: 'none'
  },
  input: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 25,
    paddingRight: 25,
    borderRadius: 10,
    backgroundColor: Colors.grey,
    display: 'block',
    width: '100%',
    border: '1px solid ' + Colors.grey,
    fontFamily: 'OpenSans_800ExtraBold',
    color: '#FFF'
  },
  label: {
    marginTop: 20,
    color: '#FFF',
    display: 'block',
    fontFamily: 'OpenSans_800ExtraBold'
  },
  modal: {
    backgroundColor: Colors.dark,
    color: '#FFF'
  }
}
