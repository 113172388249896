import { useState, useEffect } from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, ActivityIndicator } from 'react-native';
import { Editor } from './components/Editor';
import { Navbar } from './components/Navbar';
import { Checkout } from './components/Checkout';
import Constants from './scripts/Constants';
import Colors from './scripts/Colors';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { GestureHandlerRootView } from 'react-native-gesture-handler';

import { Elements } from '@stripe/react-stripe-js';
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";


const client = new ApolloClient({
  uri: Constants.api,
  cache: new InMemoryCache()
});

export default function App() {
  const [quantity, setQuantity] = useState(0);
  const [secret, setSecret] = useState(null);
  const [secretHandled, setSecretHandled] = useState(false);
  const [image, setImage] = useState(null);
  const [changes, setChanges] = useState({});
  const [eraserRecord, setEraserRecord] = useState([]);
  const [view, setView] = useState('image');
  // const [view, setView] = useState('checkout');
  const [imageRecord, setImageRecord] = useState({'id': null, 'content': null});

  useEffect(() => {
    const completedSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (completedSecret && !secretHandled) {
      setSecret(completedSecret);
      setView('checkout');
      setSecretHandled(true);
    }
  });

  const toCheckout = () => {
    if (imageRecord.content) {
      setView('checkout');
    }
  }

  const toImage = () => {
    setView('image');
  }

  return (
    <ApolloProvider client={client}>
      <Navbar quantity={quantity} view={view} toCheckout={toCheckout} toImage={toImage} />
      <View style={styles.container}>
        <Checkout view={view} changes={changes} quantity={quantity} setQuantity={setQuantity} imageRecord={imageRecord} setImageRecord={setImageRecord} eraserRecord={eraserRecord} secret={secret} image={image} leaveCheckout={toImage} />
        <Editor view={view} changes={changes} setChanges={setChanges} quantity={quantity} setQuantity={setQuantity} imageRecord={imageRecord} setImageRecord={setImageRecord} eraserRecord={eraserRecord} setEraserRecord={setEraserRecord} image={image} setImage={setImage} />
      </View>
    </ApolloProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.dark,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
