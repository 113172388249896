import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, Text, View, ActivityIndicator, Image } from 'react-native';
import Colors from './../scripts/Colors';
import Constants from './../scripts/Constants';
import { StripeProvider } from './StripeProvider';
import { ImageOverlay } from './ImageOverlay';
import { ExtrasModal } from './ExtrasModal';
import { Ionicons } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';
import { CirclePicker } from 'react-color';
import { Row, Col } from 'react-bootstrap';

import { useMutation, useLazyQuery, gql } from "@apollo/client";

export const RECORD_MUTATION = gql`
  mutation newImageRecord($id: ID!, $content: String!, $changes: Changes) {
    createImageRecord(originalImageId: $id, content: $content, changes: $changes) {
      imageRecord {
        id
      }
    }
  }
`;

export const RECORD_QUERY = gql`
  query getImageRecord($id: ID!) {
    imageRecord(recordId: $id) {
      imageRecord {
        id
        content
      }
    }
  }
`;

export const Checkout = ({ view, changes, quantity, setQuantity, imageRecord, setImageRecord, eraserRecord, image, leaveCheckout, secret=null }) => {
  const [show, setShow] = useState(false);
  const saveImageRecord = () => {
    console.log("Saved")
  }
  const saveParams = {};
  // const [saveImageRecord, { data, loading, error }] = useMutation(RECORD_MUTATION);
  // const saveParams = {
  //   id: imageRecord ? imageRecord.originalImageId : null,
  //   content: imageRecord ? imageRecord.content : null,
  //   eraserRecord: eraserRecord
  // }

  const [tirId, setTirId] = useState(null);

  useEffect(() => {
    let tir = window.location.pathname;
    if (tir && !tirId) {
      tir = parseInt(tir.replace('/', '').replace('/', ''));
      if (tir) {
        setTirId(tir);
        setImageRecord(null);
      }
    }
  });

  useEffect(() => {
    if (tirId !== null && imageRecord === null) {
      fetch(Constants.base_url + "/tir/" + tirId, {
        method: "GET",
      })
      .then((res) => res.text())
      .then((data) => {
        setImageRecord({
          id: tirId,
          content: data
        });
      });
    }
  });

  if (view !== 'checkout') {
    return (<View></View>)
  }

  return (
    <View style={styles.container}>
      <ExtrasModal secret={secret} show={show} setShow={setShow} />
      <Row style={{ flex: 1, margin: 0 }}>
        <Col style={{padding: 30}} xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
          <Text style={[styles.text, {fontSize: 24, paddingBottom: 25}]}>Payment</Text>
          <View style={{margin: 10}}></View>
          <StripeProvider tirId={tirId} setTirId={setTirId} setQuantity={setQuantity} changes={changes} quantity={quantity} imageRecord={imageRecord} leaveCheckout={leaveCheckout} secret={secret} saveImageRecord={saveImageRecord} saveParams={saveParams} />
        </Col>
        <Col style={{backgroundColor: Colors.grey, borderRadius: 5}} xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
          <View style={{ flex: 1, margin: 25, position: 'relative' }}>
            {image ? <Image style={styles.image} source={{uri: image.content}} /> : null }
            {imageRecord ? <Image style={styles.canvas} source={{uri: imageRecord.content}} /> : null }
            <View style={styles.cardWrapper}>
              <Row style={{ flex: 1 }}>
                <Col xs={12} style={{ marginTop: 20 }}><Text style={[styles.text, {fontSize: 24}]}>Purchase Details</Text></Col>
                <Col xs={6} style={{ marginTop: 20 }}><Text style={styles.textMuted}>Cost / Pixel</Text></Col>
                <Col xs={6} style={{ textAlign: 'right', marginTop: 20 }}><Text style={styles.text}>$1.00</Text></Col>
                <Col xs={6}><Text style={styles.textMuted}>Pixels Changed</Text></Col>
                <Col xs={6} style={{ textAlign: 'right' }}><Text style={styles.text}>{quantity}</Text></Col>
                <Col xs={6} style={{ marginTop: 15 }}><Text style={styles.textMuted}>Total Cost</Text></Col>
                <Col xs={6} style={{ textAlign: 'right', marginTop: 15 }}><Text style={styles.text}>${quantity}</Text></Col>
              </Row>
            </View>
          </View>
        </Col>
      </Row>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    fontFamily: 'OpenSans_800ExtraBold',
    position: 'relative'
  },
  cart: {
    backgroundColor: Colors.grey,
    padding: 0
  },
  image: {
    width: '100%',
    height: undefined,
    aspectRatio: 600/400,
    borderRadius: 5
  },
  canvas: {
    width: '100%',
    height: undefined,
    aspectRatio: 600/400,
    zIndex: 2,
    position: 'absolute',
    top: 0,
    borderRadius: 5
  },
  textMuted: {
    fontSize: 16,
    color: Colors.greyLight,
    fontFamily: 'OpenSans_800ExtraBold'
  },
  text: {
    fontSize: 16,
    color: '#FFF',
    fontFamily: 'OpenSans_800ExtraBold'
  }
});
