import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, ActivityIndicator, Image, Touchable } from 'react-native';

export const ImagePixel = ({x, y, color, currentColor}) => {
  if (!row) {
    return (
      <View>
        <Text>Error.</Text>
      </View>
    );
  }

  return (
    <View onPress={() => updateImage(x, y)} style={[styles.pixel, !!color ? {backgroundColor: color} : {opacity: 0}]}></View>
  );
}

const styles = StyleSheet.create({
  pixel: {
    width: 8,
    height: 8
  }
});
