import React, { useEffect, useState } from "react";
import { TouchableOpacity, Text, ActivityIndicator } from "react-native";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Colors from './../scripts/Colors';
import Constants from './../scripts/Constants';
import { Row, Col } from 'react-bootstrap';

export const CheckoutForm = ({ complete, tirId, leaveCheckout, saveImageRecord, saveParams }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (complete) {
      setMessage("Purchase complete!")
    }
  })

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: Constants.app_url + '/' + tirId + '/',
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
    // await saveImageRecord({
    //   variables: saveParams,
    //   onCompleted: async (data) => {
    //   }
    // });
  };

  return (
    <form style={{ flex: 1, width: '100%' }} id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />

      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}

      <Row style={{ margin: 0 }}>
        <Col xs={6} style={{ marginTop: 30, padding: 0 }}>
          <TouchableOpacity style={[styles.button, {backgroundColor: Colors.grey, width: 125, height: 41, textAlign: 'center' }]} onPress={leaveCheckout}>
            <Text style={styles.buttonText}>Go Back</Text>
          </TouchableOpacity>
        </Col>
        <Col xs={6} style={{ marginTop: 30, padding: 0, textAlign: 'right' }}>
          <button style={styles.button} disabled={isLoading || !stripe || !elements} id="submit">
            <span style={styles.buttonText} id="button-text">
              {isLoading ? <ActivityIndicator /> : "Pay now"}
            </span>
          </button>
        </Col>
      </Row>
    </form>
  );
}

const styles = {
  buttonText: {
    fontSize: 12,
    color: '#FFF',
    fontFamily: 'OpenSans_800ExtraBold'
  },
  button: {
    fontSize: 14,
    backgroundColor: Colors.primary,
    borderRadius: 10,
    color: '#FFF',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 25,
    fontFamily: 'OpenSans_800ExtraBold',
    border: 'none'
  }
}
