import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, ActivityIndicator, Image } from 'react-native';
import Colors from './../scripts/Colors';
import { ImageOverlay } from './ImageOverlay';
import { Ionicons } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { CirclePicker } from 'react-color';

import { useQuery, gql } from "@apollo/client";
import { Dimensions } from 'react-native';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export const IMAGE_QUERY = gql`
  query getPack {
    imagePack {
      errors
      success
      image {
        id
        name
        content
      }
      imageRecord {
        id
        content
      }
    }
  }
`;

export const Editor = ({ view, changes, setChanges, quantity, setQuantity, imageRecord, setImageRecord, eraserRecord, setEraserRecord, image, setImage }) => {
  const defaultLeft = -9999;
  const defaultRight = -9999;
  const defaultTop = 0;

  let initialScale = windowWidth / 600;
  if (600 * initialScale > 850) {
    initialScale = 850 / 600;
  }
  let defaultWidth = initialScale * 600;
  let defaultHeight = initialScale * 400;

  const [prevPos, setPrevPos] = useState(null);
  const [left, setLeft] = useState(defaultLeft);
  const [right, setRight] = useState(defaultRight);
  const [top, setTop] = useState(defaultTop);
  const [painting, setPainting] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [originalImageRecord, setOriginalImageRecord] = useState(null);
  const [original, setOriginal] = useState({});
  const [changesOrdered, setChangesOrdered] = useState([]);
  const [unDoneChangesOrdered, setUnDoneChangesOrdered] = useState([]);
  const [using, setUsing] = useState('paint');
  const [currentColor, setCurrentColor] = useState(Colors.black);
  const [scale, setScale] = useState(1);
  const { data, loading, error } = useQuery(IMAGE_QUERY);

  const getMousePos = (canvas, e) => {
    let rect = canvas.getBoundingClientRect(); // abs. size of element
    let scaleX = canvas.width / rect.width;    // relationship bitmap vs. element for X
    let scaleY = canvas.height / rect.height;  // relationship bitmap vs. element for Y

    let x = 0;
    let y = 0;
    if(e.type == 'touchstart' || e.type == 'touchmove' || e.type == 'touchend' || e.type == 'touchcancel'){
      var touch = e.touches[0] || e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
      x = touch.clientX;
      y = touch.clientY;
    } else if (e.type == 'mousedown' || e.type == 'mouseup' || e.type == 'mousemove' || e.type == 'mouseover'|| e.type=='mouseout' || e.type=='mouseenter' || e.type=='mouseleave') {
      x = e.clientX;
      y = e.clientY;
    }

    return {
      x: (x - rect.left) * scaleX,   // scale mouse coordinates after they have
      y: (y - rect.top) * scaleY     // been adjusted to be relative to element
    }
  }

  const getMousePosGlobal = (e) => {
    let x = 0;
    let y = 0;
    if(e.type == 'touchstart' || e.type == 'touchmove' || e.type == 'touchend' || e.type == 'touchcancel'){
      var touch = e.touches[0] || e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
      x = touch.clientX;
      y = touch.clientY;
    } else if (e.type == 'mousedown' || e.type == 'mouseup' || e.type == 'mousemove' || e.type == 'mouseover'|| e.type=='mouseout' || e.type=='mouseenter' || e.type=='mouseleave') {
      x = e.clientX;
      y = e.clientY;
    }

    return {
      x: x,   // scale mouse coordinates after they have
      y: y     // been adjusted to be relative to element
    }
  }

  const toHex = (r, g, b) => {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }


  const handleDraw = (c, pos) => {
    let pixelX = Math.floor(pos.x / 10);
    let pixelY = Math.floor(pos.y / 10);
    let key = `${pixelX}-${pixelY}`;
    let x = pixelX * 10;
    let y = pixelY *10;

    if (!changes[key] || changes[key][0] !== currentColor) {
      let ctx = c.getContext("2d");
      let data = ctx.getImageData(x, y, 1, 1).data;
      let prev = data[3] !== 0 ? toHex(data[0], data[1], data[2]) : null;

      if (prev === currentColor && !changes[key]) {
        return;
      }

      if (!currentColor) {
        if (data[3] === 0) {
          return;
        } else {
          eraserRecord.push({x: x, y: y});
          setEraserRecord(eraserRecord);
        }
      }

      setUnDoneChangesOrdered([]);
      if (!changes[key]) {
        original[key] = prev;
        setOriginal(original)
        changes[key] = [];
      }

      changes[key].unshift(currentColor);
      setChanges(changes);
      changesOrdered.push(key);
      setChangesOrdered(changesOrdered);

      if (prev === original[key]) {
        setQuantity(quantity + 1)
      } else if (currentColor === original[key] && quantity > 0) {
        setQuantity(quantity - 1)
      }

      if (currentColor) {
        ctx.fillStyle = currentColor;
        ctx.fillRect(x, y, 10, 10);
      } else {
        ctx.clearRect(x, y, 10, 10);
      }
      console.log(changes);
    }
  }

  const handleMouseMove = (e) => {
    if (painting && using === 'paint') {
      let c = document.getElementById("image-canvas");
      if (c) {
        handleDraw(c, getMousePos(c, e));
      }
    } else if (painting && using === 'move') {
      let c = document.getElementById("image-canvas");
      if (c) {
        if (!prevPos) {
          setPrevPos(getMousePosGlobal(e));
          return;
        }

        let pos = getMousePosGlobal(e);
        let diffX = (prevPos.x - pos.x);
        let diffY = (prevPos.y - pos.y);

        // console.log("Left", left, "Right", right, "Top", top, "Pos", pos, "PrevPos", prevPos);
        setLeft(left - diffX);
        setRight(right + diffX);
        setTop(top - diffY);
        setPrevPos(pos);
      }
    }
  }

  const handleMouseDown = (e) => {
    setPainting(true);
    let c = document.getElementById("image-canvas");
    if (c) {
      if (using === 'paint') {
        handleDraw(c, getMousePos(c, e));
      } else if (using === 'move') {
        let pos = getMousePosGlobal(e);
        setPrevPos(pos);
      }
    }
  }

  const handleMouseUp = (e) => {
    setPainting(false);
    setPrevPos(null);
    if (using === 'paint') {
      setImageRecord({
        originalImageId: image.id,
        content: document.getElementById("image-canvas").toDataURL("image/png")
      });
    }
  }

  const handleMouseOut = (e) => {
    setPainting(false);
  }

  const handleMove = (e) => {
    setPainting(false);
    setUsing('move');
  }

  useEffect(() => {
    if (!loading && !loaded) {
      let i = data.imagePack.image;
      if (i) {
        setImage(i);
      }

      let ir = data.imagePack.imageRecord;
      if (ir) {
        setOriginalImageRecord(ir);
        setImageRecord(ir);
        var c = document.getElementById("image-canvas");
        if (c && !loaded) {
          var ctx = c.getContext("2d");
          var img = new window.Image();
          img.onload = function(){
            ctx.drawImage(img, 0, 0);
          };
          img.src = imageRecord.content;
          setLoaded(true);
        }
      }
    }
  });

  if (loading || !image) {
    return (
      <ActivityIndicator color={Colors.blue} />
    );
  }

  const pickColor = (color, event) => {
    setUsing('paint')
    setCurrentColor(color.hex);
  }

  const undo = () => {
    if (changesOrdered.length) {
      let key = changesOrdered.pop();
      let prev = changes[key].shift();
      unDoneChangesOrdered.push([key, prev]);

      let pixels = key.split('-');
      let pixelX = parseInt(pixels[0]) * 10;
      let pixelY = parseInt(pixels[1]) * 10;

      var c = document.getElementById("image-canvas");
      let ctx = c.getContext("2d");

      let new_color = null
      if (changes[key].length) {
        new_color = changes[key][0]
      } else {
        new_color = original[key];
      }

      if (new_color) {
        ctx.fillStyle = new_color;
        ctx.fillRect(pixelX, pixelY, 10, 10);
      } else {
        ctx.clearRect(pixelX, pixelY, 10, 10);
      }

      if (prev === original[key]) {
        setQuantity(quantity + 1)
      } else if (new_color === original[key] && quantity > 0) {
        setQuantity(quantity - 1)
      }

      if (!prev) {
        for (let i = eraserRecord.length-1; i >= 0; i--) {
          if (eraserRecord[i].x === pixelX && eraserRecord[i].y === pixelY) {
            eraserRecord.splice(i, 1);
          }
        }
        setEraserRecord(eraserRecord);
      }

      setImageRecord({
        originalImageId: image.id,
        content: c.toDataURL("image/png")
      });
    }
  }

  const redo = () => {
    if (unDoneChangesOrdered.length) {
      let change = unDoneChangesOrdered.pop();
      console.log(change);
      let key = change[0];
      let prev = null;
      if (!changes[key]) {
        changes[key] = [];
      } else if (changes[key].length){
        prev = changes[key][0];
      }
      changesOrdered.push(key);
      changes[key].unshift(change[1]);

      let pixels = key.split('-');
      let pixelX = parseInt(pixels[0]) * 10;
      let pixelY = parseInt(pixels[1]) * 10;

      var c = document.getElementById("image-canvas");
      let ctx = c.getContext("2d");

      console.log(prev);
      console.log(original[key])
      console.log(changes[key][0])
      console.log("---")
      if (prev === original[key] || (prev === null && original[key] !== changes[key][0])) {
        setQuantity(quantity + 1)
      } else if (changes[key][0] === original[key] && quantity > 0) {
        setQuantity(quantity - 1)
      }

      if (changes[key][0]) {
        ctx.fillStyle = changes[key][0];
        ctx.fillRect(pixelX, pixelY, 10, 10);
      } else {
        ctx.clearRect(pixelX, pixelY, 10, 10);
        eraserRecord.push({x: pixelX, y: pixelY});
        setEraserRecord(eraserRecord);
      }

      setImageRecord({
        originalImageId: image.id,
        content: c.toDataURL("image/png")
      });
    }
  }

  const clearCanvas = () => {
    setChanges({});
    setChangesOrdered([]);
    setUnDoneChangesOrdered([]);
    setQuantity(0);

    var c = document.getElementById("image-canvas");
    if (c) {
      let ctx = c.getContext("2d");
      ctx.clearRect(0, 0, defaultWidth, defaultHeight)
      var img = new window.Image();
      img.onload = function(){
        ctx.drawImage(img, 0, 0);
      };
      img.src = originalImageRecord.content;
    }
  }

  const imageChanged = () => {
    console.log('Changed')
  }

  const handleRecenter = () => {
    setLeft(defaultLeft);
    setRight(defaultRight);
    setTop(defaultTop);
  }

  const zoomOut = () => {
    setScale(Math.max(scale - 0.25, 0.25));
  }

  const zoomIn = () => {
    setScale(Math.min(scale + 0.25, 4));
  }

  const setEraser = () => {
    setCurrentColor(null);
  }

  let canvasStyles = {
    borderRadius: 5,
    backgroundColor: 'rgba(255, 0, 0, 0)',
    position: 'absolute',
    top: top,
    left: left,
    right: right,
    margin: 'auto',
    width: defaultWidth * scale,
    height: defaultHeight * scale,
    cursor: 'pointer',
    zIndex: 3,
    touchAction: 'none'
  }

  if (view !== 'image') {
    return (<View></View>)
  }

  return (
    <View style={styles.container}>
      <View style={styles.toolbar}>
        <Text style={styles.textBubble}>{image.name}</Text>
        <View style={styles.colorBubble}>
          <CirclePicker width={170} colors={[Colors.black, Colors.blue, Colors.green, Colors.red, Colors.purple, Colors.yellow]} circleSize={16} circleSpacing={10} onChangeComplete={pickColor} />
          <FontAwesome5 style={{}} name="eraser" size={16} color={Colors.greyLight} onPress={setEraser} />
        </View>
        <FontAwesome5 style={[styles.textBubble, {color: Colors.grey}]} name="trash" size={16} color={Colors.grey} onPress={clearCanvas} />
      </View>

      <View style={styles.imageProvider}>
        <Image style={[styles.image, {width: defaultWidth * scale, height: defaultHeight * scale, top: top, left: left, right: right}]} source={{uri: image.content}} />
        <canvas id="image-canvas" style={canvasStyles} width='600px' height='400px' onMouseMove={handleMouseMove} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onTouchMove={(e) => { setPainting(true); handleMouseMove(e); }} onTouchEnd={handleMouseUp} onMouseOut={handleMouseOut}></canvas>
      </View>
      <View style={styles.controlsWrapper}>
        <View style={styles.controls}>
          <FontAwesome5 style={[using === 'paint' ? styles.controlActive : styles.control, {paddingTop: 6}]} name="paint-brush" size={16} color={using === 'paint' ? '#FFF' : Colors.grey} onPress={() => setUsing('paint')} />
          <Ionicons style={[using === 'move' ? styles.controlActive : styles.control, {paddingTop: 4}]} name="md-move" size={18} color={using === 'move' ? '#FFF' : Colors.grey} onPress={handleMove} />
          { left !== defaultLeft || right !== defaultRight || top !== defaultTop ? <MaterialCommunityIcons style={styles.control} name="set-center" size={16} color={Colors.grey} onPress={handleRecenter} /> : null }
          <FontAwesome5 style={styles.control} name="minus" size={16} color={Colors.grey} onPress={zoomOut} />
          <FontAwesome5 style={styles.control} name="plus" size={16} color={Colors.grey} onPress={zoomIn} />
          {/* <Ionicons style={styles.control} name="color-fill" size={24} color={Colors.grey} /> */}
          <FontAwesome5 style={styles.control} name="undo" size={16} color={Colors.grey} onPress={undo} />
          <FontAwesome5 style={styles.control} name="redo" size={16} color={Colors.grey} onPress={redo} />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    position: 'relative',
    overflow: 'hidden'
  },
  imageProvider: {
    width: '100%',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'center',
    position: 'relative'
  },
  toolbar: {
    width: '100%',
    paddingVertical: 10,
    paddingHorizontal: 30,
    backgroundColor: Colors.grey,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 35,
    overflow: 'scroll',
    zIndex: 10,
    position: 'relative'
  },
  textBubble: {
    backgroundColor: Colors.dark,
    color: '#FFF',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 25,
    fontFamily: 'OpenSans_800ExtraBold',
    marginRight: 20
  },
  colorBubble: {
    backgroundColor: Colors.dark,
    color: '#FFF',
    paddingVertical: 10,
    borderRadius: 25,
    fontFamily: 'OpenSans_800ExtraBold',
    paddingHorizontal: 15,
    marginRight: 20,
    flexDirection: 'row',
    alignContent: 'center'
  },
  controlsWrapper: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    justifyContent: 'center',
    flexDirection: 'row'
  },
  controls: {
    backgroundColor: Colors.grey,
    padding: 15,
    flexDirection: 'row',
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15
  },
  control: {
    backgroundColor: Colors.dark,
    width: 30,
    height: 30,
    borderRadius: 15,
    paddingTop: 7,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 5
  },
  controlActive: {
    backgroundColor: Colors.primary,
    width: 30,
    height: 30,
    borderRadius: 15,
    paddingTop: 7,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 5
  },
  image: {
    borderRadius: 5,
    position: 'absolute',
    margin: 'auto',
    zIndex: 1
  }
});
