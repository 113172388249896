let primary = '#4263eb';

export default {
  dark: '#202529',
  grey: '#343a41',
  greyLight: '#7e858d',
  primary: primary,
  red: '#cd1c40',
  green: '#1ccd38',
  blue: primary,
  yellow: '#e5e41c',
  purple: '#9c27b0',
  black: '#15191f'
}
