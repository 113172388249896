import React, { useEffect, useState } from "react";
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import Constants from './../scripts/Constants';
import Colors from './../scripts/Colors';
import { CheckoutForm } from './CheckoutForm';
import { ActivityIndicator } from 'react-native';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_BYwglCvD3byc1mNkxabovtEs00OV6yD7dy');


export const StripeProvider = ({ tirId, setTirId, changes, quantity, setQuantity, imageRecord, leaveCheckout, saveParams, saveImageRecord, secret=null }) => {
  const [clientSecret, setClientSecret] = useState("");
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    console.log("Secret", secret)
    if (!secret) {
      fetch(Constants.base_url + "/create-payment-intent", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ quantity: quantity, changes: changes }),
      })
        .then((res) => res.json())
        .then((data) => {
          setQuantity(data.quantity);
          setClientSecret(data.clientSecret);
          setTirId(data.tirId);
        });
    } else {
      setClientSecret(secret);
      setComplete(true);
    }
  }, []);

  const appearance = {
    theme: 'flat',
    labels: 'floating',
    variables: {
      colorPrimary: Colors.primary,
      colorBackground: Colors.grey,
      colorText: '#FFF'
    }
  };

  const options = {
    clientSecret,
    appearance,
  };

  if (!clientSecret) {
    return <ActivityIndicator />
  }

  return (
    <Elements style={{flex:1}} stripe={stripePromise} options={options}>
      <CheckoutForm tirId={tirId} complete={complete} leaveCheckout={leaveCheckout} saveImageRecord={saveImageRecord} saveParams={saveParams} />
    </Elements>
  );
}
