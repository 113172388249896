import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, ActivityIndicator, Image, TouchableOpacity } from 'react-native';
import Colors from './../scripts/Colors';
import { Icon } from './Icon';
import { useFonts, OpenSans_800ExtraBold } from '@expo-google-fonts/open-sans';
import { FontAwesome } from '@expo/vector-icons';
import { Dimensions } from 'react-native';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export const Navbar = ({ quantity, view, toCheckout, toImage }) => {
  let [fontsLoaded] = useFonts({ OpenSans_800ExtraBold });

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.brand} onPress={toImage}>
        <Icon />
        { Dimensions.get('window').width > 800 ? <Text style={[styles.text, {marginLeft: 50}]}>Delete or Keep</Text> : <Text style={[styles.text, {marginLeft: 50}]}>DoK</Text> }
      </TouchableOpacity>
      { quantity > 0 && view === 'image' ?
        <TouchableOpacity style={styles.button} onPress={toCheckout}>
          <Text style={styles.buttonText}>Submit {quantity} change{quantity === 1 ? '' : 's'}</Text>
        </TouchableOpacity> : null
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: Colors.dark,
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
    color: '#FFF',
    paddingVertical: 20,
    paddingHorizontal: 20,
    position: 'relative',
    zIndex: 15
  },
  brand: {
    marginTop: 7,
    marginBottom: 8
  },
  text: {
    fontSize: 18,
    color: '#FFF',
    fontFamily: 'OpenSans_800ExtraBold'
  },
  buttonText: {
    fontSize: 12,
    color: '#FFF',
    fontFamily: 'OpenSans_800ExtraBold'
  },
  button: {
    fontSize: 14,
    backgroundColor: Colors.primary,
    borderRadius: 10,
    color: '#FFF',
    paddingVertical: 10,
    paddingHorizontal: 30,
    borderRadius: 25,
    fontFamily: 'OpenSans_800ExtraBold',
    justifySelf: 'flex-end'
  },
});
