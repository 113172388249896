import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, ActivityIndicator, Image } from 'react-native';
import Colors from './../scripts/Colors';
import { useFonts, OpenSans_800ExtraBold } from '@expo-google-fonts/open-sans';
import { FontAwesome } from '@expo/vector-icons';


export const Icon = () => {
  return (
    <View style={{ position: 'relative', zIndex: 1 }}>
      <View style={styles.bubble}></View>
      <FontAwesome style={styles.diamond} name="square" size={16} color="white" />
    </View>
  );
}

const styles = StyleSheet.create({
  bubble: {
    position: 'absolute',
    backgroundColor: Colors.primary,
    top: -8,
    left: 0,
    width: 40,
    height: 40,
    borderRadius: 20
  },
  diamond: {
    zIndex: 2,
    position: 'absolute',
    top: 4,
    left: 13,
    transform: [{rotate: '45deg'}]
  }
});
