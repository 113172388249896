import React, { useState, useEffect } from 'react';
import { ImageRow } from './ImageRow';
import { StyleSheet, Text, View, ActivityIndicator, Image } from 'react-native';

export const ImageOverlay = ({ imageRecord, currentColor }) => {
  if (!imageRecord) {
    return (
      <View>
        <Text>Error.</Text>
      </View>
    );
  }

  const getRows = () => {
    return imageRecord.map((row, index) => {
      // return <ImageRow key={`image-row-${index}`} row={row} y={index} currentColor={currentColor} />
      return <View key={`image-row-${index}`} styles={{ width: 15, height: 15, backgroundColor: '#00F'}}></View>
    });
  }

  return (
    <View style={styles.overlay}>
      {getRows()}
    </View>
  );
}

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
});
